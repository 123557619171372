import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    activeRoute: "",
    bhActiveStatus:"",
    bhActiveId:"",
    bhActiveName:"",
    bhTotalRuns:null,
    titleHeader: 'Drill Plan',
    forcedPage: ""
}

export const pageSlice = createSlice({
    name: 'page',
    initialState,
    reducers: {
        updateRoute: (state, action) => {
            state.activeRoute = action.payload.activeRoute
            if (state.activeRoute === "") {
                state.titleIcon = '<i className="fa fa-home" />'
                state.titleHeader = "Drill Plan"
            }
            else if (state.activeRoute === "bh") {
                state.titleIcon = '<i class="fa fa-dot-circle-o" aria-hidden="true" />'
                state.titleHeader = action.payload.titleHeader
                state.bhActiveStatus = action.payload.bhActiveStatus
            }
            state.forcedPage = ""
        },
        updateTotalRuns: (state, action) => {
            state.bhTotalRuns = action.payload
        },
        updateActiveId: (state, action) => {
            state.bhActiveId = action.payload
        },
        updateActiveName: (state, action) => {
            state.activeName = action.payload
            state.titleHeader = action.payload
        },
        updateForcedPage: (state, action) => {
            state.forcedPage = action.payload
        },
        default: () => {
            return initialState
        }

    }
});

export const {
    updateRoute,
    updateActiveId,
    updateActiveName,
    updateTotalRuns,
    updateForcedPage
} = pageSlice.actions

export const pageSliceData = (state) => state.pageSlice;

export default pageSlice.reducer;