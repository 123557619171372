// import { configureStore } from '@reduxjs/toolkit'
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
  } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import boreholeSliceReducer from './slices/boreholeDataSlice';
import nbitSliceReducer from './slices/nbitConnectedSlice';
import selectedBoreholeReducer from './slices/selectedBoreholeSlice';
import pageReducer from './slices/pageControlSlice';

const reducer = combineReducers({
    boreholeSlice: boreholeSliceReducer,
    nbitSlice: nbitSliceReducer,
    selectedBoreholeSlice: selectedBoreholeReducer,
    pageSlice: pageReducer
})

const persistConfig = {
    key: 'root',
    storage
}

const persistedReducer = persistReducer(persistConfig, reducer)

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
})

export const persistor = persistStore(store)
