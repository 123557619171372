import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    selected: '',
    isAt: 0, //0 not started, 2 in progress, 5 complete
    depth: 0.00,
    runNumber: 0,
    timerTime: 15,
    handlerList: ["play", "calibrate", "revolution", "linePull", "event", "stop"],
    currentHandle: '',
    doneHandles: [],
    run_name:'',
    run_id: '',
}

export const selectedBoreholeSlice = createSlice({
    name: 'selectedBorehole',
    initialState,
    reducers: {
        selectBorehole: (state, action) => {
            state.selected = action.payload.name
            state.isAt = 0
            state.depth = 0.00
            state.runNumber = 0
            state.currentHandle = ''
            state.doneHandles = []
            state.run_name = action.payload.name
            state.run_id = action.payload.id
        },
        updateAll: (state, action) => {
            state.selected = action.payload.id
            state.isAt = action.payload.progress
            state.depth = action.payload.depth
            state.runNumber = action.payload.run
        },
        updateProgress: (state, action) => {
            state.isAt = action.payload
        },
        updateDepth: (state, action) => {
            state.depth = action.payload
        },
        updateRunNumber: (state, action) => {
            state.runNumber = action.payload
        },
        updateHandle: (state, action) => {
            if (state.handlerList.indexOf(action.payload) !== -1) {
                state.currentHandle = action.payload
                state.doneHandles.push(action.payload)
            }
        },
        updateRunName: (state, action) => {
            state.run_name = action.payload
        },
        default: () => {
            return initialState
        }

    }
});

export const {
    selectBorehole,
    updateAll,
    updateProgress,
    updateDepth,
    updateRunNumber,
    updateHandle,
    updateRunName
} = selectedBoreholeSlice.actions;

export const selectedBoreholeSliceData = (state) => state.selectedBoreholeSlice;

export default selectedBoreholeSlice.reducer;