import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faPlay,
    faArrowsSpin,
    faGroupArrowsRotate,
    faArrowsUpDown,
    faTriangleExclamation,
    faStop,
    faCrosshairs,
    faHashtag,
    faEllipsisVertical,
    faXmark
} from '@fortawesome/free-solid-svg-icons'
import ProgressBar from '../progressBar/progressBar';
import CountdownTimer from '../countdownTimer/CountdownTimer';

export function BoreholeContainerLeft({
    buttonEnabler,
    progressBarTime,
    progressFinish,
    startNewRun,
    startCalibration,
    startRotation,
    startLinePull,
    markEvent,
    stopRun
}) {
    return (
        <>
            <div className={`layer1 ${buttonEnabler.play === 2 ? 'moving' : buttonEnabler.play === -1 ? 'disabled' : 'pointer'}`} onClick={() => startNewRun()} >
                <div className={`holder font-6xl textShadow`} >
                    {buttonEnabler.play === 2 ?
                        <FontAwesomeIcon icon={faPlay} className="moving" aria-hidden="true" /> :
                        <FontAwesomeIcon icon={faPlay} className="pointer" />
                    }
                </div>
            </div>
            <div className={`layer2 `}>
                <div className={`holder ${buttonEnabler.calibrate === 3 ? 'pressed font-6xl' : buttonEnabler.calibrate === 2 ? 'moving font-4xl' : buttonEnabler.calibrate === 1 ? 'pointer font-6xl' : 'disabled font-6xl'}`} onClick={() => startCalibration()}>
                    {buttonEnabler.calibrate >= 2 ?
                        <FontAwesomeIcon icon={faGroupArrowsRotate} />
                        :
                        buttonEnabler.calibrate === 1 ?
                            <FontAwesomeIcon icon={faGroupArrowsRotate} className="pointer" aria-hidden="true" />
                            :
                            <FontAwesomeIcon icon={faGroupArrowsRotate} disabled />
                    }
                </div>
                {/* here for calibration */}
                {buttonEnabler.calibrate === 2 ?
                    <div className="bottom-holder m-t-3">
                        <ProgressBar duration={(progressBarTime)} onFinish={() => {}} />
                    </div> :
                    ''}
            </div>
            <div className="layer3 font-8xl">
                <div className={`l3-left ${buttonEnabler.rotate === 2 ? 'moving' : buttonEnabler.rotate === 1 ? 'pointer' : 'disabled'} `}>
                    <div className={`holder`} onClick={() => startRotation()}>
                        {buttonEnabler.rotate === 2 ?
                            <FontAwesomeIcon icon={faArrowsSpin} fade /> :
                            buttonEnabler.rotate === 1 ?
                                <FontAwesomeIcon icon={faArrowsSpin} className="pointer" aria-hidden="true" /> :
                                <FontAwesomeIcon icon={faArrowsSpin} disabled />
                        }
                    </div>
                </div>
                <div className={`l3-center ${buttonEnabler.linePull === 2 ? 'moving' : buttonEnabler.linePull === 1 ? 'pointer' : 'disabled'} `}>
                    <div className={`holder `} onClick={() => startLinePull()} >
                        {buttonEnabler.linePull === 2 ?
                            <FontAwesomeIcon icon={faArrowsUpDown} fade /> :
                            buttonEnabler.linePull === 1 ?
                                <FontAwesomeIcon icon={faArrowsUpDown} className="pointer" /> :
                                <FontAwesomeIcon icon={faArrowsUpDown} disabled />
                        }
                    </div>
                </div>
                <div className={`l3-right ${buttonEnabler.event === 2 ? 'pressed' : buttonEnabler.event === 1 ? 'pointer' : 'disabled'} `}>
                    <div className={`holder `} onClick={() => markEvent()}>
                        {buttonEnabler.event >= 1 ?
                            <FontAwesomeIcon icon={faTriangleExclamation} /> :
                            <FontAwesomeIcon icon={faTriangleExclamation} disabled />
                        }
                    </div>

                </div>
            </div>
            <div className="layer4">
                <div className={`holder ${buttonEnabler.stop === 2 ? 'pressed' : buttonEnabler.stop === 1 ? 'pointer' : 'disabled'}`} onClick={() => stopRun()} >
                    {buttonEnabler.stop === 0 ?
                        <FontAwesomeIcon icon={faStop} disabled /> :
                        <FontAwesomeIcon icon={faStop} />
                    }
                </div>
            </div>
        </>
    )
}


export function BoreholeContainerCenter({
    boreholeRunDepth,
    boreholeRunNumber,
    buttonEnabler,
    timerOut,
    viewBoxSize
}) {
    return (<>
        <div className="top">
            <div className="top-left font-l m-l-5">
                <div className="icon">
                    <FontAwesomeIcon icon={faCrosshairs} />
                </div>
                <div className="text m-l-3">
                    {boreholeRunDepth} m
                </div>
            </div>
            <div className="top-right font-l m-r-5">
                <div className="icon">
                    <FontAwesomeIcon icon={faHashtag} />
                </div>
                <div className="text m-l-3">
                    {boreholeRunNumber}
                </div>
            </div>
        </div>
        <div className="bottom">
            {buttonEnabler.play && buttonEnabler.rotate ? <CountdownTimer countdownTime={(60 * 15)} onFinish={timerOut} /> :
                <div className="countdown-timer">
                    <svg className="countdown-timer__circle" viewBox={viewBoxSize}>
                        <circle
                            className="countdown-timer__path"
                            cx={window.innerWidth * 0.0326}
                            cy={(window.innerWidth * 0.065) / 1.8}
                            r={window.innerWidth < 1000 ? window.innerWidth * 0.0266 : window.innerWidth * 0.03}
                            fill="none"
                            strokeWidth="10"
                            stroke="#ddd"
                        />
                    </svg>
                    <div className="countdown-timer__time font-3xl disabled">00:00</div>
                </div>
            }
        </div>
    </>)
}

export function BoreholeContainerRight({
    showSurveyData,
    buttonEnabler,
    closeScanPage,
    boreholeLog
}) {
    return (
        <>
            <div className="top font-2xl">
                <div className="holder m-r-3 pointer" onClick={() => showSurveyData()}>
                    <FontAwesomeIcon
                        icon={faEllipsisVertical}
                        className='pointer' />
                </div>
                <div className={`holder d-flex ${buttonEnabler.play <= 1 ? 'pointer' : ''}`} onClick={() => closeScanPage()}>
                    <FontAwesomeIcon
                        icon={faXmark}
                        className={buttonEnabler.play <= 1 ? 'ac pointer' : 'disabled'} />
                </div>
            </div>
            <div className="bottom">
                <div className="title font-s fw-bold novamera-primary-color">
                    Log
                </div>
                <div className="content font-s">
                    {boreholeLog.filter((object) => object.icon !== 0).map((object) => {
                        return (
                            <div className='d-flex m-t-1 novamera-primary-color'>
                                <span className="timestamp d-flex m-l-2">
                                    {/* <ul>
                                                    {laps.map((lap, index) => (
                                                        <li key={index}>{`${Math.floor(lap / 60000)}:${Math.floor((lap % 60000) / 1000).toString().padStart(2, '0')}:${(lap % 1000).toString().padStart(3, '0')}`}</li>
                                                    ))}
                                                </ul> */}
                                    {`${Math.floor(object.lap / 60000)}:${Math.floor((object.lap % 60000) / 1000).toString().padStart(2, '0')}`}
                                    {/* {object.timestamp} */}
                                </span>
                                <span className="icon d-flex">
                                    {object.icon === 1 ?
                                        <FontAwesomeIcon icon={faPlay} />
                                        : ''}
                                    {object.icon === 2 ?
                                        <FontAwesomeIcon icon={faGroupArrowsRotate} />
                                        : ''}
                                    {object.icon === 3 ?
                                        <FontAwesomeIcon icon={faArrowsSpin} />
                                        : ''}
                                    {object.icon === 4 ?
                                        <FontAwesomeIcon icon={faArrowsUpDown} />
                                        : ''}
                                    {object.icon === 5 ?
                                        <FontAwesomeIcon icon={faTriangleExclamation} />
                                        : ''}
                                    {object.icon === 6 ?
                                        <FontAwesomeIcon icon={faStop} />
                                        : ''}
                                </span>
                                <span className="action ellipsis">
                                    {object.action}
                                </span>

                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}

export default BoreholeContainerLeft;