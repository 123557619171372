import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    inProgressCount: 0,
    completedCount: 0,
    notStartedCount: 0,
    inProgress: [],
    completed: [],
    notStarted: []
}

export const boreholeSlice = createSlice({
    name: 'borehole',
    initialState,
    reducers: {
        setInProgress: (state, action) => {
            state.inProgress = action.payload;
            state.inProgressCount = state.inProgress.length;
        },
        appendInProgress: (state, action) => {
            state.inProgress.push(action.payload);
            state.inProgressCount = state.inProgress.length
        },
        removeInProgress: (state, action) => {
            state.inProgress = state.inProgress.filter(
                (inProg) => inProg.id !== action.payload
            );
            state.inProgressCount = state.inProgress.length
        },
        setComplete: (state, action) => {
            state.completed = action.payload;
            state.completedCount = state.completed.length
        },
        appendComplete: (state, action) => {
            state.completed.push(action.payload);
            state.completedCount = state.completed.length
        },
        removeComplete: (state, action) => {
            state.completed = state.completed.filter(
                (compl) => compl.id !== action.payload
            );
            state.completedCount = state.completed.length
        },
        setNotStarted: (state, action) => {
            state.notStarted = action.payload;
            state.notStartedCount = state.notStarted.length
        },
        appendNotStarted: (state, action) => {
            state.notStarted = action.payload;
            state.notStartedCount = state.notStarted.length
        },
        removeNotStarted: (state, action) => {
            state.notStarted = state.notStarted.filter(
                (notS) => notS.id !== action.payload
            );
            state.notStartedCount = state.notStarted.length
        },
        default: () => {
            return initialState
        }
    }
});

export const {
    setInProgress,
    appendInProgress,
    removeInProgress,
    setComplete,
    appendComplete,
    removeComplete,
    setNotStarted,
    appendNotStarted,
    removeNotStarted
} = boreholeSlice.actions

export const boreholeSliceData = (state) => state.boreholeSlice;

export default boreholeSlice.reducer;