import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useForm } from 'react-hook-form';
import {
    faXmark,
    faPenToSquare,
    faCircleInfo,
    faTriangleExclamation,
    faThumbsDown,
    faThumbsUp
} from '@fortawesome/free-solid-svg-icons'

export default function BoreholeSurveyData({
    onSubmit,
    editBoreholeSurveyData,
    setEditBoreholeSurveyData,
    setShowBoreholeSurveyData,
    boreholeSurveyData,
    buttonEnabler }) {
    const { register, handleSubmit, formState: { errors } } = useForm();
    return (
        <form onSubmit={handleSubmit(onSubmit)} autocomplete="off">
            <div className="backdrop">
                <div className="borehole-popup width-large">
                    <div className="popup-inner novamera-primary-color">
                        <div className="header d-flex m-b-2">
                            <div className="on font-m">
                                {editBoreholeSurveyData ?
                                    <>
                                        <FontAwesomeIcon
                                            icon={faPenToSquare}
                                            className='m-r-2' fade />
                                        Update Borehole Survey Data
                                    </>
                                    :
                                    <>
                                        <FontAwesomeIcon
                                            icon={faCircleInfo}
                                            className="m-r-2" />
                                        Borehole Survey Data
                                    </>
                                }
                            </div>
                            <div className="right-side font-l d-flex pointer">
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    onClick={() => setShowBoreholeSurveyData(false)}
                                />
                            </div>
                        </div>
                        <div className="content font-bold m-b-5">
                            <div className="content_row"></div>
                            <div className='content_row font-m'>
                                <div className="content_column">
                                    <div className="content_row">
                                        {editBoreholeSurveyData ?
                                            <>
                                                <label htmlFor="x">X:</label>
                                                <input
                                                    id="x"
                                                    {...register('x', {
                                                        required: 'X is required',
                                                        pattern: {
                                                            value: /^\d+(\.\d+)?$/,
                                                            message: 'Invalid number',
                                                        },
                                                        setValue: boreholeSurveyData._x
                                                    })}
                                                    type="number"
                                                    className='m-l-3'
                                                    step='0.01'
                                                    placeholder={boreholeSurveyData._x}
                                                />
                                                {errors.x ?
                                                    <>
                                                        <FontAwesomeIcon
                                                            icon={faTriangleExclamation}
                                                            className='text-danger'
                                                            title={errors.x.message}
                                                        />
                                                    </>
                                                    : ''}
                                            </>
                                            :
                                            <div
                                                className='ellipsis'
                                                title={boreholeSurveyData._x}>
                                                X: {boreholeSurveyData._x}
                                            </div>
                                        }
                                    </div>
                                    <div className="content_row">
                                        {editBoreholeSurveyData ?
                                            <>
                                                <label htmlFor="y">Y:</label>
                                                <input
                                                    id="y"
                                                    {...register('y', {
                                                        required: 'Y is required',
                                                        pattern: {
                                                            value: /^\d+(\.\d+)?$/,
                                                            message: 'Invalid number',
                                                        },
                                                        setValue: boreholeSurveyData._y
                                                    })}
                                                    type="number"
                                                    className='m-l-3'
                                                    step='0.01'
                                                    placeholder={boreholeSurveyData._y}
                                                />
                                                {errors.y && (
                                                    <FontAwesomeIcon
                                                        icon={faTriangleExclamation}
                                                        className='text-danger'
                                                        title={errors.y.message}
                                                    />
                                                )}
                                            </>
                                            :
                                            <div
                                                className='ellipsis'
                                                title={boreholeSurveyData._y}>
                                                Y: {boreholeSurveyData._y}
                                            </div>
                                        }
                                    </div>
                                    <div className="content_row">
                                        {editBoreholeSurveyData ?
                                            <>
                                                <label htmlFor="z">Z:</label>
                                                <input
                                                    id="z"
                                                    {...register('z', {
                                                        required: 'Z is required',
                                                        pattern: {
                                                            value: /^\d+(\.\d+)?$/,
                                                            message: 'Invalid number',
                                                        },
                                                        setValue: boreholeSurveyData._z
                                                    })}
                                                    type="number"
                                                    className='m-l-3'
                                                    step='0.01'
                                                    placeholder={boreholeSurveyData._z}
                                                />
                                                {errors.z && (
                                                    <FontAwesomeIcon
                                                        icon={faTriangleExclamation}
                                                        className='text-danger'
                                                        title={errors.z.message}
                                                    />
                                                )}
                                            </>
                                            :
                                            <div
                                                className='ellipsis'
                                                title={boreholeSurveyData._z}>
                                                Z: {boreholeSurveyData._z}
                                            </div>
                                        }

                                    </div>
                                </div>
                                <div className='content_column'>
                                    <div className="content_row">
                                        {editBoreholeSurveyData ?
                                            <>
                                                <label htmlFor="az">Azimuth:</label>
                                                <input
                                                    id="az"
                                                    {...register('az', {
                                                        required: 'Az is required',
                                                        pattern: {
                                                            value: /^\d+(\.\d+)?$/,
                                                            message: 'Invalid number',
                                                        },
                                                        setValue: boreholeSurveyData._az
                                                    })}
                                                    type="number"
                                                    className='m-l-3'
                                                    step='0.01'
                                                    placeholder={boreholeSurveyData._az}
                                                />
                                                {errors.az && (
                                                    <FontAwesomeIcon
                                                        icon={faTriangleExclamation}
                                                        className='text-danger'
                                                        title={errors.az.message}
                                                    />
                                                )}
                                            </>
                                            :
                                            <div
                                                className='ellipsis'
                                                title={boreholeSurveyData._az}>
                                                Azimuth: {boreholeSurveyData._az}
                                            </div>
                                        }

                                    </div>
                                    <div className='content_row'>
                                        {editBoreholeSurveyData ?
                                            <>
                                                <label htmlFor="lat">Latitude:</label>
                                                <input
                                                    id="lat"
                                                    {...register('lat', {
                                                        required: 'Lat is required',
                                                        pattern: {
                                                            value: /^-?\d+(\.\d+)?$/,
                                                            message: 'Invalid number',
                                                        },
                                                        setValue: boreholeSurveyData._lat
                                                    })}
                                                    type="number"
                                                    className='m-l-3'
                                                    step='0.01'
                                                    placeholder={boreholeSurveyData._lat}
                                                />
                                                {errors.lat && (
                                                    <FontAwesomeIcon
                                                        icon={faTriangleExclamation}
                                                        className='text-danger'
                                                        title={errors.lat.message}
                                                    />
                                                )}
                                            </>
                                            :
                                            <div
                                                className='ellipsis'
                                                title={boreholeSurveyData._lat}>
                                                Latitude: {boreholeSurveyData._lat}
                                            </div>
                                        }

                                    </div>
                                    <div className='content_row'>
                                        {editBoreholeSurveyData ?
                                            <>
                                                <label htmlFor="long">Longitude:</label>
                                                <input
                                                    id="long"
                                                    {...register('long', {
                                                        required: 'Long is required',
                                                        pattern: {
                                                            value: /^-?\d+(\.\d+)?$/,
                                                            message: 'Invalid number',
                                                        },
                                                        setValue: boreholeSurveyData._long
                                                    })}
                                                    type="number"
                                                    className='m-l-3'
                                                    step='0.01'
                                                    placeholder={boreholeSurveyData._long}
                                                />
                                                {errors.long && (
                                                    <FontAwesomeIcon
                                                        icon={faTriangleExclamation}
                                                        className='text-danger'
                                                        title={errors.long.message}
                                                    />
                                                )}
                                            </>
                                            :
                                            <div
                                                className='ellipsis'
                                                title={boreholeSurveyData._long}>
                                                Longitude: {boreholeSurveyData._long}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={`content-footer ${editBoreholeSurveyData ? '' : 'center'} font-m`}>
                            {editBoreholeSurveyData ?
                                <>
                                    <div className="button prev pointer" onClick={() => setShowBoreholeSurveyData(false)}>
                                        Cancel
                                    </div>
                                    <button type="submit" className='button next pointer'>Submit</button>
                                </>
                                :
                                (buttonEnabler.play <= 1 || buttonEnabler.stop === 1) ?
                                    <button type="button" className='button next pointer' onClick={() => setEditBoreholeSurveyData(true)}>Edit</button>
                                    : ''}

                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
