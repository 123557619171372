import React, { useEffect } from 'react';
import './loader.css'

import loadingGif from '../../assets/1486.gif';
import nbitlogo from '../../assets/nbit4.png';
import loadingIcon from '../../assets/loading-waiting.gif'
import alignmentGif from '../../assets/builder-simulator-builder.gif'
import officeAlignmnent from '../../assets/office-straight.gif'
import signalLoading from '../../assets/signal-strength.gif'
import signalStatic from '../../assets/signal-base.png'

const Loader = ({ isLoading, children }) => {
    useEffect(() => {
        const imageUrls = [
            loadingGif,
            nbitlogo,
            loadingIcon,
            alignmentGif,
            officeAlignmnent,
            signalLoading,
            signalStatic
        ];
    
        imageUrls.forEach((url) => {
          const img = new Image();
          img.src = url;
        });
      }, []);


    return isLoading
        ?
        <div className="loader">
            <img src={loadingGif} alt='Loading...'/>
        </div>
        :
        children;
};

export default Loader;