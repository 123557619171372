import React, { useState } from 'react'

export default function ScanEventMarker({
    eventList,
    specifyEvent,
    inputRef
}) {

    const [eventText, setEventText] = useState('');

    return (
        <div className="backdrop">
            <form onSubmit={eventText !== '' && eventText.length > 2 ? () => specifyEvent(eventText, '') : () => {}} >
                <div className="borehole-popup width-large">
                    <div className="popup-inner novamera-primary-color">
                        <div className="header d-flex m-b-2">
                            <div className="custom-breadcrumb font-xl">
                                Mark event
                            </div>
                            {/* <div className="right-side">
                                <i onClick={() => setTextPopup(false)} className="fa fa-times font-xl"></i>
                            </div> */}
                        </div>
                        <div className="content font-bold font-l">
                            <div className="markerlist">
                                {eventList.map((marker) => {
                                    return (
                                        <div className='marker-lbl' onClick={() => specifyEvent(marker.label, marker.id)}>
                                            <b>{marker.id}</b> - {marker.label}
                                        </div>
                                    )
                                })}
                                <input type="text" placeholder="Other Issue" ref={inputRef} aria-invalid="false" autocomplete="off" onChange={(event) => setEventText(event.target.value)} />
                            </div>
                        </div>
                        <div className="content-footer center font-l">
                            {eventText !== '' && eventText.length > 2 ?
                                <button type='submit' className='button next pointer'>
                                    Save
                                    </button> 
                                :
                                <div className={`button next pointer ${eventText !== '' && eventText.length > 2 ? '' : 'disabled'}`} >
                                    Save
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}
