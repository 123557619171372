import { React, useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';

import {
    faXmark,
    faArrowUpLong,
    faArrowDownLong,
    faDownload,
    faUnlock,
    faLock,
    faWifi,
    faListCheck,
    faThumbsUp,
    faCheck,
    faPersonWalkingDashedLineArrowRight
} from '@fortawesome/free-solid-svg-icons'

import { faCircleXmark } from '@fortawesome/free-regular-svg-icons'
import ProgressBar from '../progressBar/progressBar';
import { getDepthEncoderValue } from '../boreholeRun';
import { cancelRun } from '../boreholeRun';

const API_URL = process.env.REACT_APP_NCU_SERVER_PRODUCTION;

export default function ScanStartPopupSet({
    popupData,
    closeStartPopup,
    completedDownload,
    completedQc,
    onStartConfirmation,
    confirmDownload,
    skipDownloadAndQc,
    afterStopNbitRetracted,
    ncuRestart,
    startQc,
    dataQcStatus
}) {

    const [polling, setPolling] = useState(false);
    const intervalRef = useRef(null);

    useEffect(() => {
        if (polling) {
            startPolling();
        } else {
            stopPolling();
        }

        return () => stopPolling(); // Cleanup on component unmount
    }, [polling]);

    const startPolling = () => {
        if (!intervalRef.current) {
            intervalRef.current = setInterval(async () => {
                try {
                    const response = await getDepthEncoderValue();
                    setDepthEncoder(response["response"]["depth_mm"]);
                    // Handle the API response as needed
                } catch (error) {
                    // console.error('Polling error:', error);
                }
            }, 500); // Adjust the interval as needed
        }
    };

    const stopPolling = () => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        }
    };

    const [depthEncoder, setDepthEncoder] = useState(null)
    const [skippingDownload, setSkippingDownload] = useState(null)

    const skip_the_download = () => {
        setSkippingDownload(true);
        skipDownloadAndQc()
    }

    useEffect(() => {
        if (popupData.header === "Retract Drill" && popupData.tick1 && !popupData.tick2) {
            setPolling(true)
        }
        else {
            setPolling(false)
        }
    }, [popupData])

    return (
        <div className="backdrop">
            <div className={`borehole-popup ${popupData.downloadingConfirmed === true && !popupData?.downloadingSkipped ? 'width-large' : 'width-medium'}`}>
                <div className="popup-inner novamera-primary-color">
                    <div className="header d-flex">
                        <div className="custom-breadcrumb font-xl">
                            {popupData.header}
                        </div>
                        {/* {!popupData.stopTick1 ?
                            <div className="right-side d-flex pointer font-xl">
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    onClick={() => closeStartPopup()}
                                />
                            </div> : ''} */}
                    </div>
                    <div className="content font-4xl font-bold">
                        {popupData.content === 'none' ? <></> :
                            <>
                                {!popupData.stopTick1 && !popupData.tick1 ? `${popupData.currentDepth} m` : ''}
                                {!popupData.stopTick1 && !popupData.tick2 && popupData.tick1 ?
                                    <div className="retract-holder">
                                        <div className="left-icon-holder drill-retract m-l-3">
                                            <FontAwesomeIcon icon={faArrowUpLong} bounce className="font-5xl" />
                                        </div>
                                        <div className="right-text-holder font-m">
                                            <div className="top-text">
                                                TARGET<br />
                                                <span className="target-number">
                                                    0.000
                                                </span>
                                            </div>
                                            <div className="bottom-text-holder m-t-4 m-b-4">
                                                CURRENT<br />
                                                <span className="current-number">
                                                    {depthEncoder ? depthEncoder : '0.00'}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                                {!popupData.stopTick1 && !popupData.tick3 && popupData.tick1 && popupData.tick2 ?
                                    <FontAwesomeIcon icon={faArrowDownLong} beat className="font-5xl m-t-3 m-b-3" />
                                    : ''}
                                {!popupData.stopTick1 && !popupData.tick4 && popupData.tick1 && popupData.tick2 && popupData.tick3 ?
                                    <>
                                        <FontAwesomeIcon icon={faUnlock} className="font-8xl m-t-3 m-b-3 pos-abs" />
                                        <FontAwesomeIcon icon={faLock} fade className="font-8xl m-t-3 m-b-3" />
                                    </>
                                    : ''}
                                {popupData.stopTick1 && popupData.stopTick2 && popupData.stopTick3 && popupData.downloadingConfirmed ?
                                    <></>
                                    : popupData.stopTick1 && popupData.stopTick2 && popupData.stopTick3 ?
                                        ''
                                        : popupData.stopTick1 && popupData.stopTick2 ?
                                            <FontAwesomeIcon icon={faWifi} fade className='font-8xl' />
                                            : popupData.stopTick1 ?
                                                <FontAwesomeIcon icon={faArrowUpLong} beat className='font-8xl m-t-3' />
                                                : ''}
                                {popupData?.downloadingSkipped ?
                                    <>
                                        <div className="font-m d-flex flex-column">
                                            Data QC
                                        </div>
                                        <div className="m-t-4 content-footer">
                                            <>
                                                {dataQcStatus.started ?
                                                    <>
                                                        <div className="button next font-5xl p-tb-3 p-lr-6 pointer" >
                                                            <FontAwesomeIcon icon={faListCheck} className='font-5xl' fade />
                                                        </div>
                                                        <div className="button prev font-5xl p-tb-3 p-lr-4 pointer m-l-3 disabled">
                                                            <FontAwesomeIcon icon={faCircleXmark} />
                                                        </div>
                                                    </>
                                                    :
                                                    <>
                                                        <div className="button next font-5xl p-tb-3 p-lr-6 pointer" onClick={() => startQc()}>
                                                            <FontAwesomeIcon icon={faListCheck} className='font-5xl' />
                                                        </div>
                                                        <div className="button prev font-5xl p-tb-3 p-lr-4 pointer m-l-3" onClick={() => completedQc()}>
                                                            <FontAwesomeIcon icon={faCircleXmark} />
                                                        </div>
                                                    </>
                                                }
                                            </>
                                        </div>
                                        {dataQcStatus.message !== '' && !dataQcStatus?.completed ?
                                            <div className="d-flex font-s text-danger">
                                                {dataQcStatus.message}
                                            </div>
                                            :
                                            dataQcStatus?.completed ?
                                                <>
                                                    <div className="d-flex font-s flex-column">
                                                        <div className={`font-s d-flex align-items-center justify-content-center text-center w-100 ${!dataQcStatus?.qc_response_data?.status ? 'text-danger' : 'text-success'}`}>
                                                            {dataQcStatus?.qc_response_data?.description}
                                                        </div>
                                                        {
                                                            dataQcStatus?.qc_response_data?.details &&
                                                            dataQcStatus?.qc_response_data?.details.map(eachQcCheck => {
                                                                return (
                                                                    <>
                                                                        <div className={`d-flex font-xs flex-row align-items-center ${eachQcCheck?.status ? 'text-success' : 'text-danger'}`}>
                                                                            {eachQcCheck?.status ?
                                                                                <FontAwesomeIcon icon={faCheck} className='font-inherit' />
                                                                                :
                                                                                <FontAwesomeIcon icon={faXmark} className='font-inherit' />
                                                                            }
                                                                            <span className="font-bold font-xs font-inherit m-l-2">
                                                                                {eachQcCheck?.title}: {eachQcCheck?.description} {eachQcCheck?.message}
                                                                            </span>
                                                                            <span className="font-xs font-inherit m-l-1">
                                                                            </span>
                                                                            <span className="font-xs font-inherit m-l-1">
                                                                            </span>
                                                                        </div>
                                                                        <div className={`d-flex font-xs flex-column ${eachQcCheck?.status ? 'text-success' : 'text-danger'}`}>
                                                                        </div>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                        <div className="button d-flex justify-content-center next font-s p-tb-1 p-lr-3 pointer" onClick={() => completedQc(true)}>
                                                            <button className='button d-flex font-s p-lr-3 text-center'>
                                                                OK
                                                            </button>
                                                        </div>
                                                    </div>
                                                </>
                                                : <></>
                                        }
                                    </>
                                    : popupData.downloadingConfirmed ?
                                        popupData.qcStart ?
                                            <>
                                                <div className='icon-and-progress-holder'>
                                                    <FontAwesomeIcon icon={faDownload}
                                                        flip="horizontal"
                                                        className='font-5xl m-r-4'
                                                    />
                                                    <ProgressBar duration={(1)} onFinish={() => { }} style={{ width: '80%' }} customHeightStyle={{ height: '3vh' }} />
                                                </div>
                                                <div className="font-m d-flex flex-column">
                                                    Data QC
                                                </div>
                                                <div className="m-t-4 content-footer">
                                                    <>
                                                        {dataQcStatus.started ?
                                                            <>
                                                                <div className="button next font-5xl p-tb-3 p-lr-6 pointer" >
                                                                    <FontAwesomeIcon icon={faListCheck} className='font-5xl' fade />
                                                                </div>
                                                                <div className="button prev font-5xl p-tb-3 p-lr-4 pointer m-l-3 disabled">
                                                                    <FontAwesomeIcon icon={faCircleXmark} />
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <div className="button next font-5xl p-tb-3 p-lr-6 pointer" onClick={() => startQc()}>
                                                                    <FontAwesomeIcon icon={faListCheck} className='font-5xl' />
                                                                </div>
                                                                <div className="button prev font-5xl p-tb-3 p-lr-4 pointer m-l-3" onClick={() => completedQc()}>
                                                                    <FontAwesomeIcon icon={faCircleXmark} />
                                                                </div>
                                                            </>
                                                        }
                                                    </>
                                                </div>
                                                {dataQcStatus.message !== '' && !dataQcStatus?.completed ?
                                                    <div className="d-flex font-s text-danger">
                                                        {dataQcStatus.message}
                                                    </div>
                                                    :
                                                    dataQcStatus?.completed ?
                                                        <>
                                                            <div className="d-flex font-s flex-column">
                                                                <div className={`font-s d-flex align-items-center justify-content-center text-center w-100 ${!dataQcStatus?.qc_response_data?.status ? 'text-danger' : 'text-success'}`}>
                                                                    {dataQcStatus?.qc_response_data?.description}
                                                                </div>
                                                                {
                                                                    dataQcStatus?.qc_response_data?.details &&
                                                                    dataQcStatus?.qc_response_data?.details.map(eachQcCheck => {
                                                                        return (
                                                                            <>
                                                                                <div className={`d-flex font-xs flex-row align-items-center ${eachQcCheck?.status ? 'text-success' : 'text-danger'}`}>
                                                                                    {eachQcCheck?.status ?
                                                                                        <FontAwesomeIcon icon={faCheck} className='font-inherit' />
                                                                                        :
                                                                                        <FontAwesomeIcon icon={faXmark} className='font-inherit' />
                                                                                    }
                                                                                    <span className="font-bold font-xs font-inherit m-l-2">
                                                                                        {eachQcCheck?.title}: {eachQcCheck?.description} {eachQcCheck?.message}
                                                                                    </span>
                                                                                    <span className="font-xs font-inherit m-l-1">
                                                                                    </span>
                                                                                    <span className="font-xs font-inherit m-l-1">
                                                                                    </span>
                                                                                </div>
                                                                                <div className={`d-flex font-xs flex-column ${eachQcCheck?.status ? 'text-success' : 'text-danger'}`}>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                                <div className="button d-flex justify-content-center next font-s p-tb-1 p-lr-3 pointer" onClick={() => completedQc(true)}>
                                                                    <button className='button d-flex font-s p-lr-3 text-center'>
                                                                        OK
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : <></>
                                                }
                                            </>
                                            :
                                            <>
                                                <div className="font-m d-flex flex-column">
                                                    Data Download
                                                </div>
                                                <div className='icon-and-progress-holder'>
                                                    <FontAwesomeIcon icon={faDownload}
                                                        flip="horizontal"
                                                        fade
                                                        className='font-5xl m-r-4'
                                                    />
                                                    <ProgressBar duration={(40)} onFinish={() => { }} style={{ width: '80%' }} customHeightStyle={{ height: '3vh' }} />
                                                </div>
                                            </>
                                        :
                                        <></>
                                }
                            </>}
                    </div>
                    <div className={`content-footer font-l ${!popupData.stopTick1 && !popupData.tick3 && popupData.tick1 && popupData.tick2 ? '' : !popupData.stopTick1 && !popupData.tick1 ? '' : 'center'} ${popupData.content === 'none' ? '' : 'm-t-3'}`}>
                        {popupData.content === 'none' ? <></> :
                            <>
                                {!popupData.stopTick1 && !popupData.tick1 ?
                                    <>
                                        <div className="button previous pointer font-2xl" onClick={() => ncuRestart()}>
                                            <FontAwesomeIcon icon={faXmark} />
                                        </div>
                                        <div className="button next pointer font-2xl" onClick={() => onStartConfirmation(2)}>
                                            <FontAwesomeIcon icon={faThumbsUp} />
                                        </div>
                                    </>
                                    : ''}
                                {!popupData.stopTick1 && !popupData.tick2 && popupData.tick1 ?
                                    <>
                                        <div className="button previous pointer font-2xl" onClick={() => ncuRestart()}>
                                            <FontAwesomeIcon icon={faXmark} />
                                        </div>
                                        <div className="button next pointer font-2xl" onClick={() => onStartConfirmation(3)}>
                                            <FontAwesomeIcon icon={faThumbsUp} />
                                        </div>
                                    </>
                                    : ''}
                                {!popupData.stopTick1 && !popupData.tick3 && popupData.tick1 && popupData.tick2 ?
                                    <>
                                        <div className="button previous pointer font-2xl" onClick={() => ncuRestart()}>
                                            <FontAwesomeIcon icon={faXmark} />
                                        </div>
                                        <div className="button next pointer font-2xl" onClick={() => onStartConfirmation(4)}>
                                            <FontAwesomeIcon icon={faThumbsUp} />
                                        </div>
                                    </>
                                    : ''}
                                {!popupData.stopTick1 && !popupData.tick4 && popupData.tick1 && popupData.tick2 && popupData.tick3 ?
                                    <>
                                        <div className="button previous pointer font-2xl" onClick={() => ncuRestart()}>
                                            <FontAwesomeIcon icon={faXmark} />
                                        </div>
                                        <div className="button next pointer font-2xl" onClick={() => onStartConfirmation(5)}>
                                            <FontAwesomeIcon icon={faThumbsUp} />
                                        </div>
                                    </>
                                    : ''}

                                {popupData.stopTick1 && popupData.stopTick2 && popupData.stopTick3 && popupData.downloadingConfirmed ?
                                    '' :
                                    popupData.stopTick1 && popupData.stopTick2 && popupData.stopTick3 && !popupData.downloadingConfirmed ?
                                        <>
                                            <div className="button next font-5xl p-tb-3 p-lr-6 pointer" onClick={() => confirmDownload()}>
                                                <FontAwesomeIcon icon={faDownload} flip="horizontal" className='font-5xl' />
                                            </div>
                                            <div className="button prev font-5xl p-tb-3 p-lr-4 pointer m-l-3" onClick={() => skip_the_download()}>
                                                {skippingDownload ?
                                                    <FontAwesomeIcon
                                                        fade
                                                        icon={faPersonWalkingDashedLineArrowRight}
                                                    />
                                                    :
                                                    <FontAwesomeIcon
                                                        icon={faPersonWalkingDashedLineArrowRight}
                                                    />
                                                }
                                            </div>
                                        </>
                                        :
                                        popupData.stopTick1 && popupData.stopTick2 ?
                                            '' :
                                            popupData.stopTick1 ?
                                                <div className="button next font-2xl pointer m-t-3" onClick={() => afterStopNbitRetracted()}>
                                                    <FontAwesomeIcon icon={faThumbsUp} />
                                                </div>
                                                : ''}
                            </>}
                    </div>
                </div>
            </div>
        </div>
    )
}
