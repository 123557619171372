import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faXmark,
    faChevronDown
} from '@fortawesome/free-solid-svg-icons'

export default function DrilledBoreholeNameUpdate({formSubmit, page, newName, handleInput, cancelUpdateBoreholePlannedName}) {
    return (
        <form onSubmit={formSubmit} autocomplete="off">
            <div className="backdrop">
                <div className="borehole-popup width-medium">
                    <div className="popup-inner novamera-primary-color">
                        <div className="header d-flex">
                            <div className="custom-header font-m">
                                Update BH Name
                            </div>
                            <div className="right-side font-l d-flex pointer">
                                <FontAwesomeIcon
                                    icon={faXmark}
                                    onClick={() => cancelUpdateBoreholePlannedName()}
                                />
                            </div>
                        </div>
                        {/* <FontAwesomeIcon icon={faArrowTurnDown} fade className='m-l-5 fa-3x' /> */}
                        <div className="content font-bold font-l">
                            <div className="previous-name">
                                {page.titleHeader}
                            </div>
                            <div className="spacer m-t-2">
                                <FontAwesomeIcon icon={faChevronDown} bounce />
                            </div>
                            <div className="new-name novamera-primary-color">
                                <input type="text"
                                    ref={newName}
                                    placeholder="XX-XX-XXX"
                                    aria-invalid="false"
                                    autocomplete="off"
                                    className='font-3xl novamera-primary-color font-bold'
                                    onInput={handleInput}
                                />
                            </div>
                        </div>
                        <div className="content-footer m-t-5 font-m">
                            <div className="button prev pointer" onClick={() => cancelUpdateBoreholePlannedName()}>
                                Cancel
                            </div>
                            {/* <div className="button next pointer" onClick={() => updateBoreholePlannedName(true, page.titleHeader)}>
                                        Save
                                    </div> */}
                            <button type="submit" className="button next pointer">
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    )
}
