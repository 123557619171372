import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    ping: false,
    selected: '',
    radar: false,
    toolFaceAngle: null,
    battery_level:0,
    network_signal:0,
    run_name:''
}

export const nbitSlice = createSlice({
    name: 'nbit',
    initialState,
    reducers: {
        nbitConnected: (state, action) => {
            state.ping = action.payload
        },
        nbitDisconnected: (state, action) => {
            state.ping = false
            state.selected = ''
            state.radar = false
            state.toolFaceAngle = null
            state.run_name = ''
        },
        nbitSelected: (state, action) => {
            if (state.ping) {
                state.selected = action.payload
            }
        },
        nbitUnderground: (state, action) => {
            state.ping = false
        },
        connectedRadar: (state, action) => {
            state.radar = true
        },
        setToolFaceAngle: (state, action) => {
            state.toolFaceAngle = action.payload
        },
        setBatteryLevel: (state, action) => {
            state.battery_level = action.payload
        },
        setNetworkSignal: (state, action) => {
            state.network_signal = action.payload
        },
        setRunName: (state, action) => {
            state.run_name = action.payload
        },
        default: () => {
            return initialState
        }
    }
});

export const {
    nbitConnected,
    nbitDisconnected,
    nbitSelected,
    nbitUnderground,
    connectedRadar,
    setToolFaceAngle,
    setBatteryLevel,
    setNetworkSignal,
    setRunName
} = nbitSlice.actions

export const nbitSliceData = (state) => state.nbitSlice;

export default nbitSlice.reducer;